// Scrollspy
var scrollSpy = new bootstrap.ScrollSpy(document.body, {
  target: '#sidebarMenu'
});

// menu
$(function ($) {
  if (!localStorage.getItem('cookie')) {
    $('#privacyAcpt').show()
  }

  // Archivisto modal
  $('#archivistoModal').on('show.bs.modal', function (event) {
    var button = event.relatedTarget
    var imgClas = button.getAttribute('data-bs-path')
    var headingTxt = button.getAttribute('data-bs-title')
    $('#archivistoModalLabel').html(headingTxt)
    $('#picture').attr('src', $('.' + imgClas).attr('src'))
  })

  // Imprestor modal
  $('#pricingModal').on('show.bs.modal', function (event) {
    $('#pricingBody .packages').hide()
    var button = event.relatedTarget
    var ulPath = button.getAttribute('data-bs-path')
    $('#pricingModalLabel').html($('h3.' + ulPath).html())
    $('#' + ulPath).fadeIn()
  })

  $("#close-sidebar").click(function () {
    $(".page-wrapper").removeClass("toggled");
  });
  $("#show-sidebar").click(function () {
    $(".page-wrapper").addClass("toggled");
  });

  $('#sidebar .nav-link').click(function () {
    $(".page-wrapper").toggleClass("toggled");
  })

  $('#btnConsent').click(function (e) {
    e.preventDefault()
    localStorage.setItem('cookie', 'true')
    $('#privacyAcpt').fadeOut()
  })

  $("#contactForm").submit(function (event) {
    event.preventDefault();
    if (!grecaptcha.getResponse()) {
      alert('Please select reCAPTCHA.')
      return
    }
    var $inputs = $('#contactForm :input');
    var values = {};
    $inputs.each(function () {
      values[this.name] = $(this).val();
    });
    values.consent = values.consent === '1' ? 1 : 0

    $('#btnSbmit').attr('disabled', true);
    $.ajax({
      url: "http://nubes.leda.grapsol.be/backend/calldb.php?saveToEnquiry",
      data: values,
      type: "POST",
      success: function (data) {
        $('#formMsg').fadeIn().delay(8000).fadeOut();
        $inputs.each(function () {
          $(this).val('');
        });
        $('#btnSbmit').attr('disabled', false);
      },
      error: function () {
        $('#formMsgErr').fadeIn().delay(8000).fadeOut();
        $('#btnSbmit').attr('disabled', false);
      }
    });

  });

});

// animations
AOS.init({
  once: true,
});
